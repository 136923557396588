<template>
  <BaseContainer>
    <PAYGCheckout />
  </BaseContainer>
</template>

<script>
import PAYGCheckout from '@components/Plans/PAYGCheckout'

export default {
  components: {
    PAYGCheckout
  },

  page: {
    title: 'Start Subscription'
  }
}
</script>
